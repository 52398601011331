import React, {Component}                              from 'react'
import PropTypes                                       from 'prop-types'
import {getMoreByContentfulId, getVideoByContentfulId} from './drawer-items'
import VerticalCenter                                  from './components/vertical-center'
import {videoIsLockedByContentfulID, moreVideoIsLockedByID}      from './user'
import {resetMetadata}                                 from './actions/metas'
import VimeoPlayer                                     from './components/vimeo-player';

export default class VideoPlayerContainer extends Component {
    constructor(props, ctx){
        super(props, ctx)

        let {id, related, vimeoId}  = props.location.query
        let videoID, locked

        if(vimeoId) {
            locked = false
            videoID = vimeoId
        } else if (related) {
            const moreItem = getMoreByContentfulId(id)
            locked  = moreVideoIsLockedByID(related.id)
            videoID = moreItem.vimeoId
        } else {
            const videoItem = getVideoByContentfulId(id)
            locked  = videoIsLockedByContentfulID(id)
            videoID = videoItem.vimeoId
        }

        this.state = {videoID, locked}

        this.onBGClick = this.onBGClick.bind(this)

    }
    componentWillMount(){
        resetMetadata()
    }
    componentDidMount(){
        const {locked} = this.state
        if (locked) {
            const {history} = this.props
            history.replace('/')
            window.subs()
            return
        }
        window.pauseAudioIfNeeded && window.pauseAudioIfNeeded()
    }
    componentWillUnmount(){
        window.resumeAudioIfNeeded && window.resumeAudioIfNeeded()
    }


    onBGClick(e){
        if (e.target === this.refs.background) {
            const { history } = this.props;
          
            if (history.action === 'POP') {
              history.push('/');
            } else {
              history.goBack();
            }
          }
    }
    render(){
        const {locked, videoID} = this.state
        if (locked) return null
        return (
            <div id="video-player" ref="background" onClick={this.onBGClick}>
              <VerticalCenter className="player">
                <VimeoPlayer focus={true} videoId={videoID + '?autoplay=1'} />
              </VerticalCenter>
            </div>
        )
    }
}

VideoPlayerContainer.contextTypes = {
    router: PropTypes.object.isRequired
}
